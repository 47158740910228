module.exports = [{
      plugin: require('/home/runner/work/kurl.sh/kurl.sh/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"100"},
    },{
      plugin: require('/home/runner/work/kurl.sh/kurl.sh/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-61420213-15"},
    },{
      plugin: require('/home/runner/work/kurl.sh/kurl.sh/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/kurl.sh/kurl.sh/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
