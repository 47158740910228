// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-docs-template-js": () => import("/home/runner/work/kurl.sh/kurl.sh/src/templates/DocsTemplate.js" /* webpackChunkName: "component---src-templates-docs-template-js" */),
  "component---src-pages-add-ons-js": () => import("/home/runner/work/kurl.sh/kurl.sh/src/pages/add-ons.js" /* webpackChunkName: "component---src-pages-add-ons-js" */),
  "component---src-pages-docs-js": () => import("/home/runner/work/kurl.sh/kurl.sh/src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-download-js": () => import("/home/runner/work/kurl.sh/kurl.sh/src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/kurl.sh/kurl.sh/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

